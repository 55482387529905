import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const shadowimg = `${process.env.PUBLIC_URL}../assets/img/shadowimg.png`;
// const shadowimg = `${process.env.PUBLIC_URL}../../`;
function Confirmation() {
    const [searchParams] = useSearchParams();
    const [eventlist, setEventList] = useState(null); // Updated to null 
    const [configinfo, setConfigInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const userId = localStorage.getItem("v3_user_id");
    const EventId = searchParams.get("id");
    const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));
    const [greetingCards, setGreetingCards] = useState([]);
    const [gifts, setGifts] = useState([]);

    useEffect(() => {
        const pathname = location.pathname.startsWith("/v3");
        if (pathname) {
            document.body.classList.add("bgyellow");
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        if (eventlist && eventlist.cart) {
            const fetchItems = async () => {
                const greetingPromises = eventlist.cart
                    .filter(item => item.greeting_card)
                    .map(item =>
                        axios
                            .get(`${process.env.REACT_APP_APIURL}greeting/list?greeting_id=${item.greeting_card}`)
                            .then(res => ({
                                ...res.data.result,
                                cartId: item._id,
                                order_cound: item.order_data_count
                            }))
                    );

                const giftPromises = eventlist.cart
                    .filter(item => item.gift)
                    .map(item =>
                        axios
                            .get(`${process.env.REACT_APP_APIURL}gift/list?gift_id=${item.gift}`)
                            .then(res => ({
                                ...res.data.result,
                                cartId: item._id,
                                order_cound: item.order_data_count
                            }))
                    );

                const greetings = await Promise.all(greetingPromises);
                const giftsData = await Promise.all(giftPromises);

                setGreetingCards(greetings.flat());
                setGifts(giftsData.flat());
            };

            fetchItems();
        }
    }, [eventlist]);

    const fetchData = () => {
        setLoading(true);
        fetchconfiginfo();
        const key = userId ? "user_id" : "random_string";
        const value = userId || randomId;

        const url = !EventId
            ? `${process.env.REACT_APP_APIURL}event/list?${key}=${value}&checkoutlist=true`
            : `${process.env.REACT_APP_APIURL}event/list?checkoutlist=true&event_id=${EventId}`;

        axios
            .get(url)
            .then((res) => {
                if (res.data.status === "success") {
                    setEventList(res.data.result);
                } else {
                    toast.error("Failed to fetch event data.");
                }
            })
            .catch((err) => {
                console.error("Error fetching events:", err);
                toast.error("Error fetching events.");
            })
            .finally(() => {
                setLoading(false);
            });
    };
    const fetchconfiginfo = () => {
        const url = `${process.env.REACT_APP_APIURL}config/info`
        axios
            .get(url)
            .then((res) => {
                if (res.data.status === "success") {
                    setConfigInfo(res.data.result);
                } else {
                    console.error("Failed to fetch event data.");
                }
            })
            .catch((err) => {
                console.error("Error fetching events:", err);

            })
            .finally(() => {
                setLoading(false);
            });
    };
    const formatDate = (dateString) => {
        if (!dateString) return "N/A";
        const [day, month, year] = dateString.split("-");
        const monthNames = [
            "Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec",
        ];
        return `${day} ${monthNames[parseInt(month, 10) - 1]} ${year}`;
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!eventlist) {
        return <div>No events available for checkout.</div>;
    }

    const fullAddress = [
        eventlist?.address,
        eventlist?.city,
        eventlist?.state,
        eventlist?.zip_code,
    ].filter(Boolean).join(", ");



    const calculateTotalAmount = (cart, greetingCards, gifts) => {
        let total = 0;

        cart.forEach((item) => {
            const quantity = item.order_data_count;
            if (item.greeting_card) {
                const card = greetingCards.find((gc) => gc._id === item.greeting_card);
                if (card) total += parseInt(card.price * quantity || 0);
            }
            if (item.gift) {
                const gift = gifts.find((g) => g._id === item.gift);
                if (gift) total += parseInt(gift.price * quantity || 0);
            }
        });

        return total;
    };

    const eventsum = calculateTotalAmount(eventlist.cart, greetingCards, gifts);

    const shippingCost = parseFloat(configinfo.shipping_charge);
    const { tax } = configinfo;
    const taxAmount = (eventsum * (tax / 100)).toFixed(2);

    const totalAmount = (shippingCost + eventsum + parseFloat(taxAmount)).toFixed(2);

    const renderGreetingCards = () =>
        greetingCards.length > 0 ? (
            greetingCards.map((card, index) => (
                <div key={index} className="greetingcardgift_wrapper">
                    <div className="greetingcardgift_item">
                        <div className="greetingcardgift_img_sm position-relative">
                            <img src={card.url} alt={card.name} />
                        </div>
                        <div className="greetingcardgift_price">
                            <span>{card.name}</span>
                            <span className="f_sz14 w-100"> Rs. {card.price}.00</span>
                        </div>
                        <div className="greetingcardgift_qty">
                            <span className="qtysection w-100 justify-content-center mt-1"><span className="f_sz16 fw_pjs600 me-2">Quantity:</span> {card.order_cound}</span>
                        </div>

                    </div>
                </div>
            ))
        ) : (
            // <div>No greeting cards added.</div>
            ""
        );
    const renderGifts = () =>
        gifts.length > 0 ? (
            gifts.map((gift, index) => (
                <div key={index} className="greetingcardgift_wrapper">
                    <div className="greetingcardgift_item">
                        <div className="greetingcardgift_img_sm position-relative">
                            <img src={gift.url} alt={gift.name} />

                        </div>
                        <div className="greetingcardgift_price">
                            <span>{gift.name}</span>
                            <span className="f_sz14 w-100"> Rs. {gift.price}.00</span>
                        </div>
                        <div className="greetingcardgift_qty">
                            <span className="qtysection w-100 justify-content-center mt-1"><span className="f_sz16 fw_pjs600 me-2">Quantity:</span> {gift.order_cound}</span>
                        </div>
                    </div>
                </div>
            ))
        ) : (
            // <div>No gifts added.</div>
            ""
        );


    return (
        <>
            <main>
                <section className="tp-login-area pt-150 pb-140 p-relative fix">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                                <div className="position-relative">
                                    <div className="row mb-30 align-items-center">
                                        <div className="col-7 col-sm-7 col-lg-7 col-xl-7">
                                            <h3 className="tp-login-title">Confirmation</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                                            <div className="confirmation_message">
                                                <div className="confirmation_msgicon"><i className="fa-solid fa-gift" /></div>
                                                <div className="w-100 text-center mt-25 f_sz26 fw_pjs600 f_darkyellow">Your Order Confirmed</div>
                                                <div className="w-100 text-center mt-25 f_sz20">We will send you a shipping confirmation email as soon as your order ships.</div>
                                            </div>
                                            <div className="confirm-details-item-wrapper">
                                                <div className="row">
                                                    <div className="col-sm-4">
                                                        <div className="confirm-order-details-item">
                                                            <h5>Order Date:</h5>
                                                            <p className="f_sz16">{new Date().toLocaleDateString()}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="confirm-order-details-item">
                                                            <h5>Order Number:</h5>
                                                            <p className="f_sz16">#9641</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-4">
                                                        <div className="confirm-order-details-item">
                                                            <h5>Payment Method:</h5>
                                                            <p className="f_sz16">NEFT</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                                            <div className="table-responsive">
                                                <table width="100%" cellPadding={0} cellSpacing={0} className="table table-bordered" style={{ width: '100%' }}>
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center">S.No</th>
                                                            <th className="text-center">Details</th>
                                                            <th style={{ minWidth: 150 }} className="text-center">Quantity</th>
                                                            <th style={{ minWidth: 150 }} className="text-center">Status</th>
                                                            <th style={{ minWidth: 250 }} className="text-center">Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td width={50}>1</td>
                                                            <td width={800}>
                                                                <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-calendar-days f_darkyellow mr-10" /><span className="fw_pjs700 f_darkgreen f_sz16">{formatDate(eventlist.event_date)}</span></div>
                                                                <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-user f_darkyellow mr-10" /><span className="fw_pjs700">{eventlist.event_name}</span></div>
                                                                <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-calendar-check f_darkyellow mr-10" />{eventlist.event_type}</div>
                                                                <div className="txtdsply f_sz14 mb-5"><i className="fa-solid fa-phone f_darkyellow mr-10" />{eventlist.phone_number}</div>
                                                                <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-map-location-dot f_darkyellow mr-10" />
                                                                    {fullAddress}
                                                                </div>
                                                                <div className="brdr_btm w-100 mt-10 mb-10" />
                                                                <div className="flexdiv">
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                                                        <div className="fw_pjs700">Greeting Card</div>
                                                                        <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-file-pen f_darkyellow mr-10" />
                                                                            {eventlist.greeting_notes}
                                                                        </div>
                                                                        {renderGreetingCards()}
                                                                    </div>
                                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                                                        <div className="fw_pjs700">Gift</div>
                                                                        {renderGifts()}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-center f_sz16">1</td>
                                                            <td className="text-center">
                                                                <div className="order_stat order_ordered">Ordered</div>
                                                                <div className="mt-10"><span className="fw_pjs700">Expected Delivery:</span><br /> {formatDate(eventlist.event_date)}</div>
                                                            </td>
                                                            <td className="text-center fw_pjs700 f_sz16">
                                                                Rs. {eventsum}.00
                                                            </td>
                                                        </tr>
                                                    </tbody><tfoot>
                                                        <tr>
                                                            <td colSpan={4} className="txtalgn_rgt fw_pjs700">
                                                                <div className="f_sz18">Subtotal</div>
                                                                <div className="f_sz18 mt-10">Shipping</div>
                                                                <div className="f_sz18 mt-10">Tax</div>
                                                                <div className="f_sz22 mt-10">Total for sending greeting card and gift </div>
                                                            </td>
                                                            <td className="txtalgn_rgt fw_pjs700">
                                                                <div className="f_sz18 mt-10">Rs. {eventsum}.00</div>
                                                                <div className="f_sz18 mt-10">Rs. {shippingCost}.00</div>
                                                                <div className="f_sz18 mt-10">Rs. {taxAmount}</div>
                                                                <div className="f_sz18 mt-10">Rs. {totalAmount}</div>
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* login area end */}
            </main>
            <ToastContainer />
        </>
    );
}

export default Confirmation;

