import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ForgotPasswordV3() {
  const [email, setEmail] = useState("");
  const [submit, setSubmit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const pathname = location.pathname.startsWith('/v3');
    if (pathname) {
      console.log("Adding classes to the body.");
      document.body.classList.add("bgyellow");
    }
  }, []);
  const handleForgotPassword = (e) => {
    e.preventDefault();

    if (!email.trim()) {
      toast.error("Email is required");
      return;
    }
    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format");
      return;
    }

    // API Call
    axios
      .post(`${process.env.REACT_APP_APIURL}newuser/password/forgot`, {
        email_address: email,
      })
      .then((res) => {
        if (res.data.status === "success") {
          setSubmit(true);
          toast.success("Check your email for password reset link.");
        }
        else if (res.data.status_code == 409) {
          toast.error(res.data.message);
        }
        else {
          if (res.data.error && res.data.error.message) {
            toast.error(res.data.error.message);
          } else {
            toast.error("An error occurred during password reset");
          }
        }
      })
      .catch((err) => {
        setSubmit(true);
        // console.error("Password reset error:", err.response.data.error.message);
        toast.error("Reset password link already sent to your email.");
      });
  };

  return (
    <main>
      <ToastContainer />
      <section className="tp-login-area pt-150 pb-140 p-relative fix">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-8">
              <div className="tp-login-wrapper position-relative">
                <div className="tp-login-top text-center mb-30">
                  <h3 className="tp-login-title">Reset Password</h3>
                  <p>Enter your email address to receive the  password reset link.</p>
                </div>
                <div className="tp-login-option">
                  <form onSubmit={handleForgotPassword}>
                    <div className="tp-login-input-wrapper">
                      <div className="tp-login-input-box">
                        <div className="tp-login-input">
                          <input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="tp-login-input-title">
                          <label htmlFor="email">Your Email</label>
                        </div>
                      </div>
                    </div>
                    <div className="tp-login-bottom mb-15">
                      {
                        submit ? <button type="submit" className="btn btn-secondary w-100 m-2 disabled" disabled>
                        Submit
                      </button> :
                      <button type="submit" className="btn btn-yellow w-100">
                        Submit
                      </button>
                      }
                      
                    </div>
                  </form>
                  <div className="tp-login-suggestions d-sm-flex align-items-center justify-content-center">
                    <div className="tp-login-forgot">
                      <span>
                        Remember Password?{" "}
                        <a href="/v3/login" className="f_darkyellow">
                          Login
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default ForgotPasswordV3;
