import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const emptycart = `${process.env.PUBLIC_URL}../assets/img/emptycart.png`;
function Cart() {
  const [userId, setUserId] = useState(localStorage.getItem("v3_user_id"));
  const [randomId, setRandomId] = useState(localStorage.getItem("v3_random_id"));

  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [display, setDisplay] = useState(false);
  const [configinfo, setConfigInfo] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [eventId, setEventId] = useState(null);
  const [cartId, setCartId] = useState(null);

  const calculatedTotals = useMemo(() => {
    const totalSum = cartData.reduce((sum, item) => {
      const hasGiftOrCard = item.cart_details?.some(
        (cartItem) => cartItem.gift || cartItem.greeting_card
      );

      return hasGiftOrCard ? sum + (item.total_price || 0) : sum;
    }, 0);

    const shippingCost = 60;
    const tax = "5";
    const taxAmount = (totalSum * (tax / 100)).toFixed(2);
    const totalAmount = (shippingCost + totalSum + parseFloat(taxAmount)).toFixed(2);

    return {
      totalSum,
      shippingCost,
      taxAmount,
      totalAmount
    };
  }, [cartData]);

  useEffect(() => {
    const pathname = window.location.pathname;
    if (pathname === "/v3/cart") {
      document.body.classList.add("bgyellow");
    }
    if (!userId) {
      // window.location.href = "/v3/login";
    }

    fetchCartList();

  }, []);

  const fetchCartList = async () => {
    setLoading(true);
    fetchconfiginfo();
    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}event/list?${key}=${value}&checkoutlist=true`
      );

      if (res.data.status === "success") {
        setCartData(res.data.result);
        if (res.data.result.length > 0) {
          setDisplay(true);
        }

      } else {
        setDisplay(false);
        if (res.data.status != "no_record") {
          // toast.error("Failed to fetch greeting data");
          console.log("Failed to fetch greeting data");
        }
        setCartData([]);
      }
    } catch (err) {
      console.error("Error fetching cart data:", err);
      console.log("Failed to fetch cart data");
      // toast.error("Failed to fetch cart data");
    } finally {
      setLoading(false);
    }
  };

  const handleCheckout = (id, action) => {
    if (action == "checkout") {
      window.location.href = `/v3/checkout?id=${id}`;
    }

  }

  const fetchconfiginfo = () => {
    const url = `${process.env.REACT_APP_APIURL}config/info`
    axios
      .get(url)
      .then((res) => {
        if (res.data.status === "success") {
          setConfigInfo(res.data.result);
        } else {
          console.log("Failed to fetch event data.");
          // toast.error("Failed to fetch event data.");
        }
      })
      .catch((err) => {
        console.error("Error fetching events:", err);
        // toast.error("Error fetching events.");
        console.log("Error fetching events.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteEvent = async (id) => {

    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;

    const payload = {
      [key]: value,
      event_id: id
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}event/delete`,
        payload
      );

      if (response.data.status === "success") {
        window.location.reload();
        // fetchCartList();
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      toast.error("Failed to add to cart");
    }

  };

  const handlesingleproductDelete = async (cartId, eventId) => {

    try {
      const updatedcartData = cartData.map(event => {
        if (event._id === eventId) {
          const updatedCartDetails = event.cart_details.filter(item => item._id !== cartId);

          // Recalculate total price for the event
          const newTotalPrice = updatedCartDetails.reduce((total, cartItem) => {
            const itemPrice = cartItem.gift
              ? cartItem.gift.price * cartItem.order_data_count
              : cartItem.greeting_card
                ? cartItem.greeting_card.price * cartItem.order_data_count
                : 0;
            return total + itemPrice;
          }, 0);

          return {
            ...event,
            // cart_details: event.cart_details.filter(item => item._id !== cartId)
            cart_details: updatedCartDetails,
            total_price: newTotalPrice
          };
        }
        return event;
      });

      const filteredCartData = updatedcartData.filter(event =>
        event.cart_details.length > 0
      );

      setCartData(filteredCartData);

      const newCartIds = filteredCartData
        .find(event => event._id === eventId)?.cart_details
        .map(item => item._id)
        .toString() || '';

      const apiUrl = `${process.env.REACT_APP_APIURL}event/update`;
      const payload = {
        event_id: eventId,
        cart_ids: newCartIds,
      };

      const response = await axios.post(apiUrl, payload);

      if (response.data.status === "success") {
        console.log("Item removed successfully");
        setDisplay(filteredCartData.length > 0);
      }
    } catch (error) {
      console.error("Error removing item:", error);
      console.log("Failed to remove item");
    }
  };

  const handlQuantityClick = async (giftId, count, itemType, incrementType, cartid) => {
    const newQuantity = incrementType === "increment" ? count + 1 : count - 1;

    if (newQuantity < 1) {
      toast.error("Minimum quantity is 1");
      return;
    }

    const payload = {
      ...(userId ? { user_id: userId } : { random_string: randomId }),
      order_data_count: newQuantity,
      cart_id: cartid,
      ...(itemType === "greeting_card"
        ? { greeting_card: giftId }
        : { gift: giftId })
    };
    // console.log("payload", payload)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_APIURL}event/add/cart`,
        payload
      );

      if (response.data.status === "success") {
        // Fetch only the updated cart data
        await fetchUpdatedCartData();
      } else {
       console.log("Failed to update cart");
        // toast.error("Failed to update cart");
      }
    } catch (error) {
      console.error("Error updating cart:", error);
      console.log("Failed to update cart");
      // toast.error("Failed to update cart");
    }
  };

  const fetchUpdatedCartData = async () => {
    const key = userId ? "user_id" : "random_string";
    const value = userId || randomId;

    try {
      const res = await axios.get(
        `${process.env.REACT_APP_APIURL}event/list?${key}=${value}&checkoutlist=true`
      );

      if (res.data.status === "success") {
        setCartData(res.data.result);
        setDisplay(res.data.result.length > 0);
      } else {
        console.log("Failed to fetch cart data");
        // toast.error("Failed to fetch cart data");
        setCartData([]);
        setDisplay(false);
      }
    } catch (err) {
      console.error("Error fetching cart data:", err);
      console.log("Failed to fetch cart data");
      // toast.error("Failed to fetch cart data");
    }
  };

  if (loading) {
    return <div>Loading your cart...</div>;
  }
  return (
    <div>
      <div className="content">
        <main>
          <section className="tp-login-area pt-150 pb-140 p-relative fix">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-12 col-md-12 col-lg-12 col-xl-12">
                  <div className="position-relative">
                    <div className="row mb-30 align-items-center">
                    {display ? (
                      <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        <h3 className="tp-login-title">Cart</h3>
                      </div>
                    ) : (
                      ''
                    )}

                    </div>
                    {display ? (
                      
                      <div className="row">

                        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                          <div className="table-responsive">

                            <table width="100%" cellPadding={0} cellSpacing={0} className="table table-bordered" style={{ width: '100%' }}>
                              {cartData.length > 0 && (
                                <thead>
                                  <tr>
                                    <th style={{ minWidth: 50 }} className="text-center">S.No</th>
                                    <th style={{ minWidth: 600 }} className="text-center">Details</th>
                                    <th style={{ minWidth: 250 }} className="text-center">Quantity</th>
                                    <th style={{ minWidth: 250 }} className="text-center">Price</th>
                                  </tr>
                                </thead>
                              )}
                              <tbody>

                                {cartData.map((item, index) => {
                                  const hasGiftOrCard = item.cart_details?.some(
                                    (cartItem) => cartItem.gift || cartItem.greeting_card
                                  );

                                  return hasGiftOrCard ? (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>
                                        <div className="txtdsply f_sz14 mb-5">
                                          <i className="fa-solid fa-calendar-days f_darkyellow mr-10" />
                                          <span className="fw_pjs700 f_darkgreen f_sz16">{item.event_date}</span>
                                        </div>
                                        <div className="txtdsply f_sz14 mb-5">
                                          <i className="fa-solid fa-user f_darkyellow mr-10" />
                                          <span className="fw_pjs700">{item.event_name}</span>
                                        </div>
                                        <div className="txtdsply f_sz14 mb-5">
                                          <i className="fa-solid fa-calendar-check f_darkyellow mr-10" />
                                          {item.event_type}
                                        </div>
                                        <div className="txtdsply f_sz14 mb-5">
                                          <i className="fa-solid fa-phone f_darkyellow mr-10" />
                                          +91{item.phone_number}
                                        </div>
                                        <div className="txtdsply f_sz14 mb-10">
                                          <i className="fa-solid fa-map-location-dot f_darkyellow mr-10" />
                                          {item.address + ", " + item.city + ", " + item.state + ", " + item.zip_code}
                                        </div>
                                        <div className="brdr_btm w-100 mt-10 mb-10" />
                                        <div className="flexdiv" >
                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                            <div className="fw_pjs700">Greeting Card</div>
                                            <div className="txtdsply f_sz14 mb-10"><i className="fa-solid fa-file-pen f_darkyellow mr-10" />
                                              {item.greeting_notes}
                                            </div>
                                            {item.cart_details.filter(cartItem => cartItem.greeting_card).map((cartItem, cartIndex) => (
                                              <>

                                                <div className="greetingcardgift_wrapper">
                                                  <div className="greetingcardgift_item">
                                                    <div className="greetingcardgift_img_sm position-relative">
                                                      <img
                                                        src={cartItem.greeting_card.url}
                                                        alt={cartItem.greeting_card.name}
                                                        border={0}
                                                      />
                                                      {item.cart_details.length == 1 ?
                                                        <div className="delete_icon_sm">
                                                          <i data-bs-toggle="modal" data-bs-target="#modal_event_singleproduct" onClick={() => setDeleteId(item._id)}
                                                            className="fa-regular fa-trash-can f_sz18 f_red" />
                                                        </div>
                                                        :
                                                        <div className="delete_icon_sm">
                                                          <i data-bs-toggle="modal" data-bs-target="#modal_removegreetromcart" onClick={() => {
                                                            setCartId(cartItem._id);
                                                            setEventId(item._id);
                                                          }} className="fa-regular fa-trash-can f_sz18 f_red" />
                                                        </div>}

                                                    </div>
                                                    <div className="greetingcardgift_price">
                                                      <span>{cartItem.greeting_card.name}</span>
                                                      <span className="f_sz14 w-100">
                                                        Rs. {cartItem.greeting_card.price}.00
                                                      </span>
                                                    </div>
                                                    <div className="flexdiv justify-content-center align-items-center mt-5">
                                                      <h6 className="me-3">Quantity</h6>
                                                      <div className="tp-product-quantity mt-10 mb-10">
                                                        <span className="tp-cart-minus">
                                                          <i onClick={() => handlQuantityClick(cartItem.greeting_card.greeting_card_id, cartItem.order_data_count, "greeting_card", "decrement", cartItem._id)} className="fa-solid fa-minus crsr_pntr" />
                                                        </span>
                                                        <input className="tp-cart-input" type="text" value={cartItem.order_data_count} readOnly />
                                                        <span className="tp-cart-plus">
                                                          <i onClick={() => handlQuantityClick(cartItem.greeting_card.greeting_card_id, cartItem.order_data_count, "greeting_card", "increment", cartItem._id)} className="fa-solid fa-plus crsr_pntr" />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </div>

                                          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
                                            {item.cart_details.filter(cartItem => cartItem.gift).map((cartItem, cartIndex) => (
                                              <>
                                                <div className="fw_pjs700">Gift</div>
                                                <div className="greetingcardgift_wrapper">
                                                  <div className="greetingcardgift_item">
                                                    <div className="greetingcardgift_img_sm position-relative">
                                                      <img
                                                        src={cartItem.gift.url}
                                                        alt={cartItem.gift.name}
                                                        border={0}
                                                      />
                                                      {item.cart_details.length == 1 ?
                                                        <div className="delete_icon_sm">
                                                          <i data-bs-toggle="modal" data-bs-target="#modal_event_singleproduct" onClick={() => setDeleteId(item._id)}
                                                            className="fa-regular fa-trash-can f_sz18 f_red" />
                                                        </div>
                                                        :
                                                        <div className="delete_icon_sm">
                                                          <i data-bs-toggle="modal" data-bs-target="#modal_removeGiftfromcart" onClick={() => {
                                                            setCartId(cartItem._id);
                                                            setEventId(item._id);
                                                          }} className="fa-regular fa-trash-can f_sz18 f_red" />
                                                        </div>}
                                                    </div>
                                                    <div className="greetingcardgift_price">
                                                      <span>{cartItem.gift.name}</span>
                                                      <span className="f_sz14 w-100">
                                                        Rs. {cartItem.gift.price}.00
                                                      </span>
                                                    </div>
                                                    <div className="flexdiv justify-content-center align-items-center mt-5">
                                                      <h6 className="me-3">Quantity</h6>
                                                      <div className="tp-product-quantity mt-10 mb-10">
                                                        <span className="tp-cart-minus">
                                                          <i onClick={() => handlQuantityClick(cartItem.gift.gift_id, cartItem.order_data_count, "gift", "decrement", cartItem._id)} className="fa-solid fa-minus crsr_pntr" />
                                                        </span>
                                                        <input className="tp-cart-input" type="text" value={cartItem.order_data_count} readOnly />
                                                        <span className="tp-cart-plus">
                                                          <i onClick={() => handlQuantityClick(cartItem.gift.gift_id, cartItem.order_data_count, "gift", "increment", cartItem._id)} className="fa-solid fa-plus crsr_pntr" />
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </>
                                            ))}
                                          </div>
                                        </div>
                                        <div className="brdr_btm w-100 mt-10 mb-10" />
                                        <div className="w-100 d-flex flex-row flex-wrap">
                                          <div>
                                            <a href={`/v3/greeting_card?event_id=${item._id}`} className="btn btn-yellow btn-sm m-2">
                                              Add Another Greeting Card
                                            </a>
                                          </div>
                                          <div>
                                            <a href={`/v3/gift?event_id=${item._id}`} className="btn btn-yellow btn-sm m-2">
                                              Add Another Gift
                                            </a>
                                          </div>
                                          <div>
                                            <a onClick={() => { handleCheckout(item._id, "checkout"); }} className="btn btn-dgreen btn-sm m-2">
                                              Proceed To Checkout
                                            </a>
                                          </div>
                                          <div>
                                            <a data-bs-toggle="modal" data-bs-target="#modal_removefromcart" onClick={() => setDeleteId(item._id)} className="btn btn-danger btn-sm m-2">Delete</a>
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-center f_sz16">{item.cart_details.length}</td>
                                      <td className="text-center fw_pjs700 f_sz16">Rs.{item.total_price}.00</td>
                                    </tr>
                                  ) : null;
                                })}
                              </tbody>
                              {cartData.length > 0 && (
                                <tfoot>
                                  <tr>
                                    <td colSpan={3} className="txtalgn_rgt fw_pjs700">
                                      <div className="f_sz18">Subtotal</div>
                                      <div className="f_sz18 mt-10">Shipping</div>
                                      <div className="f_sz18 mt-10">Tax</div>
                                      <div className="f_sz22 mt-10">Total for sending greeting card and gift </div>
                                    </td>
                                    <td className="text-center fw_pjs700">
                                      <div className="f_sz18">Rs. {calculatedTotals.totalSum}.00</div>
                                      <div className="f_sz18 mt-10">Rs. {calculatedTotals.shippingCost}.00</div>
                                      <div className="f_sz18 mt-10">Rs. {calculatedTotals.taxAmount}</div>
                                      <div className="f_sz22 mt-10">Rs. {calculatedTotals.totalAmount}</div>
                                    </td>
                                  </tr>
                                </tfoot>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      // <div className="row mb-30 align-items-center">
                      //   <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                      //     <div className="w-100 d-flex flex-row justify-content-center flex-wrap mt-25 f_sz16">
                      //       No carts added in your list.
                      //     </div>
                      //   </div>
                      // </div>
                      
                      <div className="row">
                        <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                          <div className="table-responsive">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="" style={{ width: '100%' }}>
                              <tbody>
                                <tr>
                                  <td className="p-4" style={{ border: 'none' }}>
                                    <div className="row align-items-center">
                                      {/* Left side - Empty cart message */}
                                      <div className="col-6 col-md-4 text-center">
                                        <div className="p-4">
                                          <img
                                            src={emptycart}
                                            alt="Empty Cart"
                                            // className="img-fluid"
                                            style={{ maxWidth:'300px' }}
                                          />
                                        </div>

                                      </div>
                                      {/* Right side - Image */}
                                      <div className="col-12 col-md-4 text-center">
                                        <div className="p-4">
                                          <h2 className="fw_pjs700 f_sz22 mb-3">Your Cart is Empty</h2>
                                          <p className="f_sz16 mb-4">Looks like you haven't added any greeting cards or gifts yet.</p>
                                          <a href="/v3" className="btn btn-yellow">
                                            Start Shopping
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="modal fade" id="modal_removefromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                <div className="modal-body">
                  <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                    <div className="greetingcardgift_wrapper justify-content-center">
                    </div>
                    Are you sure you want to remove the event.
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <a onClick={() => handleDeleteEvent(deleteId)} data-bs-dismiss="modal" className="btn btn-yellow">Yes, remove</a>
                </div>
              </div>
            </div>
          </div>
          <div className="modal fade" id="modal_removeGiftfromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                <div className="modal-body">
                  <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                    <div className="greetingcardgift_wrapper justify-content-center">
                    </div>
                    Are you sure you want to remove the gift?
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <a data-bs-dismiss="modal" onClick={() => handlesingleproductDelete(cartId, eventId)} className="btn btn-yellow">Yes, remove</a>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modal_removegreetromcart" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                <div className="modal-body">
                  <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                    <div className="greetingcardgift_wrapper justify-content-center">
                    </div>
                    Are you sure you want to remove the greeting card?
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <a data-bs-dismiss="modal" onClick={() => handlesingleproductDelete(cartId, eventId)} className="btn btn-yellow">Yes, remove</a>
                </div>
              </div>
            </div>
          </div>

          <div className="modal fade" id="modal_event_singleproduct" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="modal_removefromcartLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><i className="fa-solid fa-xmark" /></button>
                <div className="modal-body">
                  <div className="flexdiv f_sz18 justify-content-center text-center p-2">
                    <div className="greetingcardgift_wrapper justify-content-center">
                    </div>
                    There must be at least one item to create an event. Removing this item will delete the entire event. Are you sure you want to proceed?
                  </div>
                </div>
                <div className="modal-footer justify-content-center">
                  <a data-bs-dismiss="modal" onClick={() => handleDeleteEvent(deleteId)} className="btn btn-yellow">Yes, remove</a>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>

  );
}

export default Cart;