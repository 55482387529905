import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Profile() {

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [UserInfo, setUserInfo] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const togglePasswordVisibility = (field) => {
        if (field === "password") setShowPassword(!showPassword);
        if (field === "confirmPassword") setShowConfirmPassword(!showConfirmPassword);
    };

    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname === "/v3/Profile") {
            document.body.classList.add("bgyellow");
        }

        const userId = localStorage.getItem("v3_user_id");
        const first_name = localStorage.getItem("v3_first_name");
        const email_address = localStorage.getItem("v3_email_address");

        if (!userId) {
            window.location.href = "/v3";
            return;
        }
        const apiUrl = `https://api.eventseasy.in:2020/api/newuser/info?user_id=${userId}&checkoutlist=true`;

        const fetchUserInfo = async () => {
            try {
                const response = await fetch(apiUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch events');
                }
                const data = await response.json();
                setUserInfo(data.result || []);
                if (data.result) {
                    setFirstName(data.result.first_name || '');
                    setPhoneNumber(data.result.phone_number || '');
                }

                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchUserInfo();
    }, []);

    const validateFields = () => {

        console.log("Validating fields...");
        let newErrors = {};

        if (!firstName.trim()) newErrors.firstName = "Name is required.";

        console.log('password,confirmPassword')
        if (password || confirmPassword) {
            if (password !== confirmPassword) {
                newErrors.confirmPassword = "Passwords do not match.";
            }

            if (password.length > 0 && password.length < 8) {
                newErrors.password = "Password must be at least 8 characters long.";
            }

            if (password.length > 0 && !/(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*])/.test(password)) {
                newErrors.password = "Password must include uppercase, lowercase, number, and special character";
            }
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const updateApiUrl = "https://api.eventseasy.in:2020/api/newuser/profileupdate";
    const handleUpdate = async () => {

        if (validateFields()) {
            console.log("Updating profile...");

            const userId = localStorage.getItem("v3_user_id");
            const password = document.getElementById('password').value;
            const confirmPassword = document.getElementById('confirmPassword').value;
            const first_name = firstName;
            const email_address = document.getElementById('email_address').value;
            const phone = phoneNumber;

            try {
                const updatePayload = {
                    user_id: userId,
                    first_name: first_name,
                    phone_number: phone,
                };
                updatePayload.password = password


                console.log("Update payload:", updatePayload);

                const response = await fetch(updateApiUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(updatePayload)
                });

                const responseData = await response.json();

                if (response.ok && responseData.status === 'success') {
                    localStorage.setItem("v3_first_name", first_name);

                    toast.success("Profile updated succesfully.");

                    document.getElementById('password').value = '';
                    document.getElementById('confirmPassword').value = '';
                } else {
                    toast.error(responseData.message || "Failed to update profile");
                }
            } catch (error) {
                console.error("Update error:", error);
                toast.error("An error occurred while updating profile");
            }
        }
    };

    return (
        <main>
            <section className="tp-login-area pt-120 pb-140 p-relative fix">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="tp-login-wrapper position-relative">
                                <div className="row mb-30 align-items-center">
                                    <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                                        <h3 className="tp-login-title">My Profile</h3>
                                    </div>
                                </div>
                                <div className="tp-login-option">
                                    <div className="tp-login-input-wrapper">
                                        <h6 className="f_sz20 w-100 mb-35">User Name</h6>
                                        <div className="tp-login-input-box">
                                            <div className="tp-login-input">
                                                <input
                                                    id="first_name"
                                                    type="text"
                                                    value={firstName}
                                                    onChange={(e) => {
                                                        setFirstName(e.target.value);
                                                        if (errors.firstName) {
                                                            setErrors(prev => ({ ...prev, firstName: '' }));
                                                        }
                                                    }}
                                                    style={{ borderColor: errors.firstName ? "red" : "", }}
                                                />
                                            </div>
                                            <div className="tp-login-input-title">
                                                <label htmlFor="first_name">Your Name</label>
                                                {errors.firstName && (
                                                    <div className="error-text" style={{ color: "red" }}>
                                                        {errors.firstName}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <h6 className="f_sz20 w-100 mb-35">Email Address</h6>
                                        <div className="tp-login-input-box">
                                            <div className="tp-login-input">
                                                <input
                                                    id="email_address"
                                                    type="email"
                                                    value={UserInfo.email_address}
                                                    readOnly

                                                />
                                            </div>
                                            <div className="tp-login-input-title">
                                                <label htmlFor="email_address">Your Email Address</label>
                                            </div>
                                        </div>

                                        <h6 className="f_sz20 w-100 mb-35">Change Phone Number</h6>
                                        <div className="tp-login-input-box">
                                            <div className="tp-login-input">
                                                <input
                                                    id="phone"
                                                    type="tel"
                                                    maxLength={10}
                                                    value={phoneNumber}
                                                    // onChange={(e) => setPhoneNumber(e.target.value)}
                                                    onChange={(e) => {
                                                        setPhoneNumber(e.target.value);
                                                        if (errors.phoneNumber) {
                                                            setErrors(prev => ({ ...prev, phoneNumber: '' }));
                                                        }
                                                    }}
                                                    style={{ borderColor: errors.phoneNumber ? "red" : "", }}
                                                />
                                            </div>
                                            <div className="tp-login-input-title">
                                                <label htmlFor="phone">Your Phone Number</label>
                                                {errors.phoneNumber && (
                                                    <div className="error-text" style={{ color: "red" }}>
                                                        {errors.phoneNumber}
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <h6 className="f_sz20 w-100 mb-35">Change Password</h6>
                                        <div className="tp-login-input-box">
                                            <div className="tp-login-input">
                                                <input
                                                    id="password"
                                                    type={showPassword ? "text" : "password"}
                                                    value={password}
                                                    // onChange={(e) => setPassword(e.target.value)}
                                                    onChange={(e) => {
                                                        setPassword(e.target.value);
                                                        if (errors.password) {
                                                            setErrors(prev => ({ ...prev, password: '' }));
                                                        }
                                                    }}
                                                    style={{ borderColor: errors.password ? "red" : "" }}
                                                />
                                            </div>
                                            <div className="tp-login-input-title">
                                                <label htmlFor="password">New Password</label>
                                                {errors.password && (
                                                    <div className="error-text" style={{ color: "red" }}>
                                                        {errors.password}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="tp-login-input-eye cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2" id="password-show-toggle" onClick={() => togglePasswordVisibility('password')}>
                                                {showPassword ? (
                                                    <i className="fa-solid fa-eye-slash eyestyle" />
                                                ) : (
                                                    <i className="fa-solid fa-eye eyestyle" />
                                                )}

                                            </div>
                                        </div>
                                        <div className="tp-login-input-box">
                                            <div className="tp-login-input">
                                                <input
                                                    id="confirmPassword"
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    value={confirmPassword}
                                                    // onChange={(e) => setConfirmPassword(e.target.value)}
                                                    onChange={(e) => {
                                                        setConfirmPassword(e.target.value);
                                                        if (errors.confirmPassword) {
                                                            setErrors(prev => ({ ...prev, confirmPassword: '' }));
                                                        }
                                                    }}
                                                    style={{ borderColor: errors.confirmPassword ? "red" : "" }}
                                                />
                                            </div>

                                            <div className="tp-login-input-title">
                                                <label htmlFor="confirmPassword">Re-type New Password</label>
                                                {errors.confirmPassword && (
                                                    <div className="error-text" style={{ color: "red" }}>
                                                        {errors.confirmPassword}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="tp-login-input-eye cursor-pointer absolute right-3 top-1/2 transform -translate-y-1/2" id="password-show-toggle" onClick={() => togglePasswordVisibility('confirmPassword')}>
                                                {showConfirmPassword ? (
                                                    <i className="fa-solid fa-eye-slash eyestyle" />
                                                ) : (
                                                    <i className="fa-solid fa-eye eyestyle" />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tp-login-bottom">
                                        <button onClick={handleUpdate} className="btn btn-yellow w-100">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </main>
    );
}

export default Profile;
