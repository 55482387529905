import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
const ordersempty = `${process.env.PUBLIC_URL}../assets/img/ordersempty.png`;

function Orders() {
    const [events, setEvents] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState({});
    const location = useLocation();

    useEffect(() => {
        const userId = localStorage.getItem("v3_user_id");
        if (!userId) {
            window.location.href = "/v3";
            setIsLoading(false);
            return;
        }
        const pathname = location.pathname.startsWith('/v3');
        if (pathname) {
            console.log("Adding classes to the body.");
            document.body.classList.add("bgyellow");
        }
        //https://api.eventseasy.in:2020/api/event/admin/list?user_id=67498df636606f82bb4ca020

        const apiUrl = `${process.env.REACT_APP_APIURL}event/admin/list?user_id=${userId}`;
        console.log("apiUrl", apiUrl);
        const fetchEvents = async () => {
            try {
                const response = await axios.get(apiUrl);
                const data = response.data;
                const eventsData = data.result[0].events;

                const filteredEvents = eventsData.filter(event =>
                    event.cart_details?.some(cart => cart.gift || cart.greeting_card)
                );

                const initialIndexes = {};
                filteredEvents.forEach((event, index) => {
                    initialIndexes[index] = 0;
                });

                setCurrentImageIndex(initialIndexes);
                setEvents(filteredEvents);
                setIsLoading(false);
            } catch (err) {
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchEvents();
    }, []);

    const navigateImage = (eventIndex, direction) => {
        setCurrentImageIndex(prevIndexes => {
            const imageUrls = events[eventIndex].cart_details
                .filter(cart => cart.gift?.url || cart.greeting_card?.url)
                .map(cart => cart.gift?.url || cart.greeting_card?.url);

            const newIndex = prevIndexes[eventIndex] || 0;
            let nextIndex;

            if (direction === 'next') {
                nextIndex = (newIndex + 1) % imageUrls.length;
            } else {
                nextIndex = (newIndex - 1 + imageUrls.length) % imageUrls.length;
            }

            return {
                ...prevIndexes,
                [eventIndex]: nextIndex
            };
        });
    };

    const calculateEventSummary = (cartDetails) => {
        let totalProducts = 0;
        let totalQuantity = 0;

        cartDetails.forEach(cart => {
            const quantity = cart.order_data_count || 1;
            const price = cart.purchased_price || 0;

            totalProducts += 1;
            totalQuantity += quantity;
        });
        return {
            totalProducts,
            totalQuantity,
        };
    };

    return (
        <main>
            <style>
                {`
                    .rounded {
                        background-color: rgb(159 187 152) !important;
                    }
                    .cursor-pointer {
                        cursor: pointer;
                    }
                    .img-fluid {
                        transition: transform 0.2s ease-in-out;
                    }
                    .img-fluid:hover {
                        transform: scale(1.1);
                    }
                    .slider-button {
                        background-color: rgba(0, 0, 0, 0.5);
                        color: white;
                        border: none;
                        padding: 8px 12px;
                        cursor: pointer;
                        margin: 0 5px;
                        border-radius: 4px;
                    }
                    .slider-button:hover {
                        background-color: rgba(0, 0, 0, 0.7);
                    }
                    .image-container {
                        position: relative;
                        display: flex;
                        align-items: center;
                    }
                    .slider-controls {
                        display: flex;
                        justify-content: center;
                        margin-top: 10px;
                    }
                `}
            </style>
            <section className="order-area pt-150 pb-140">
                <div className="container">
                    {(events > 0) ? (
                        <div className="row mb-4">
                            <div className="col-12">
                                <h3 className="text-center">Your Orders</h3>
                            </div>
                        </div>
                    ) : ('')}
                    {isLoading ? (
                        <div className="text-center mt-5">Loading...</div>
                    ) : error ? (
                        <div className="text-center mt-5 text-danger">{error}</div>
                    ) : events.length > 0 ? (
                        events.map((event, index) => {
                            const imageUrls = event.cart_details
                                .filter(cart => cart.gift?.url || cart.greeting_card?.url)
                                .map(cart => cart.gift?.url || cart.greeting_card?.url);

                            const { totalProducts, totalQuantity } = calculateEventSummary(event.cart_details);

                            return (
                                <div key={index} className="row mb-4 p-3 bg-white shadow-sm rounded">
                                    <div className="col-3">
                                        {imageUrls.length > 0 && (
                                            <div className="image-container" style={{
                                                flexDirection: 'column',
                                                gap: '10px',
                                                width: '120px',  // Smaller fixed width like Flipkart
                                                margin: '0 auto'
                                            }}>
                                                <div className="image-wrapper" style={{
                                                    width: '120px',
                                                    height: '120px',
                                                    backgroundColor: '#fff',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '8px',
                                                    border: '1px solid #e0e0e0',
                                                    borderRadius: '4px'
                                                }}>
                                                    <img
                                                        src={imageUrls[currentImageIndex[index] || 0]}
                                                        alt="Event Image"
                                                        className="cursor-pointer"
                                                        style={{
                                                            maxWidth: '100%',
                                                            maxHeight: '100%',
                                                            objectFit: 'contain',
                                                            width: 'auto',
                                                            height: 'auto'
                                                        }}
                                                    />
                                                </div>
                                                {imageUrls.length > 1 && (
                                                    <div className="slider-controls" style={{ marginTop: '10px' }}>
                                                        <button
                                                            className="slider-button"
                                                            onClick={() => navigateImage(index, 'prev')}
                                                        >
                                                            ←
                                                        </button>
                                                        <button
                                                            className="slider-button"
                                                            onClick={() => navigateImage(index, 'next')}
                                                        >
                                                            →
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="col-3">
                                        <h5>{event.event_name}</h5>
                                        <p className="mb-1">Event Date: {event.event_date}</p>
                                        <p>
                                            Address: {event.address}, {event.city}, {event.state}
                                        </p>
                                        <p>Total Products: {totalProducts}</p>
                                        <p>Total Quantity: {totalQuantity}</p>
                                    </div>
                                    <div className="col-3 text-end">
                                        <h6>Total Price: ₹ {event.purchased_price}</h6>
                                    </div>
                                    <div className="col-3 text-end">
                                        <h6>Order Status: <span className="text-danger">{event.purchase_status}</span></h6>
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        // <div className="row mb-30 align-items-center">
                        //     <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                        //         <div className="w-100 d-flex flex-row justify-content-center flex-wrap mt-25 f_sz16">
                        //             There are no orders.
                        //         </div>
                        //     </div>
                        // </div>
                        <div className="row">
                            <div className="col-12 col-sm-12 col-lg-12 col-xl-12">
                                <div className="table-responsive">
                                    <table width="100%" cellPadding={0} cellSpacing={0} className="" style={{ width: '100%' }}>
                                        <tbody>
                                            <tr>
                                                <td className="p-4" style={{ border: 'none' }}>
                                                    <div className="row align-items-center">
                                                        {/* Left side - Empty cart message */}
                                                        <div className="col-6 col-md-4 text-center">
                                                            <div className="p-4">
                                                                <img
                                                                    src={ordersempty}
                                                                    alt="Empty Cart"
                                                                    // className="img-fluid"
                                                                    style={{ maxWidth: '300px' }}
                                                                />
                                                            </div>

                                                        </div>
                                                        {/* Right side - Image */}
                                                        <div className="col-12 col-md-4 text-center">
                                                            <div className="p-4">
                                                                <h2 className="fw_pjs700 f_sz22 mb-3">Your Orders is Empty</h2>
                                                                <p className="f_sz16 mb-4">Looks like you haven't added any greeting cards or gifts yet.</p>
                                                                <a href="/v3" className="btn btn-yellow">
                                                                    Start Shopping
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </main>
    );
}

export default Orders;