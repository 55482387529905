import React, { useState, useEffect } from "react";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";

function SearchBox({ isSearchVisible }) {
  const [searchKeyword, setSearchKeyword] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [searchParams] = useSearchParams();
  const Search = searchParams.get("keyword");

  // Set the initial value of the search input to the query parameter if available
  useEffect(() => {
    if (Search) {
      setSearchKeyword(Search);
    }
  }, [Search]);

  if (!isSearchVisible) return null;
  const handleSearchChange = (e) => {
    setSearchKeyword(e.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchKeyword.trim() !== "") {
      const queryParams = new URLSearchParams();
      queryParams.set("keyword", searchKeyword);
      navigate(`${path}?${queryParams.toString()}`);
      window.location.reload();
    }
  };
  const handleclearUrl = () => {
    const currentUrl = `${window.location.origin}${location.pathname}`;
    window.location.href = currentUrl; 
  };
  return (
    <div className="search_dsplydiv" style={{ display: "block" }}>
      <div className="justify-content-center searchwrapper">
        <div className="col-12 col-lg-4 col-xl-4">
          <div className="position-relative">
            <div className="input-group mb-3">
              <button
                className="btn btn-sm btn-yellow dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {path === "/v3/greeting_card" ? "Greeting Card" : "Gift"}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="#">
                    {path === "/v3/greeting_card" ? "Gift" : "Greeting Card"}
                  </a>
                </li>
              </ul>
              <input
                type="text"
                className="form-control search_frmcntrl"
                placeholder="Search"
                value={searchKeyword}
                onChange={handleSearchChange}
                onKeyDown={(e) => e.key === "Enter" && handleSearchSubmit()}
              />
              <span
                className="input-group-text crsr_pntr"
                onClick={() => setSearchKeyword("")}
              >
                <i className="fa-solid fa-xmark" onClick={handleclearUrl}/>
              </span>
              {/* <button className="btn btn-sm btn-gray" onClick={handleclearUrl}>Clear</button> */}
            </div>
            
            <div className="search_suggestion_div" style={{ display: "none" }}>
              <div className="search_suggest_list scrollbar_style">
                <div className="search_suggest_item">Anniversary Cards</div>
                <div className="search_suggest_item">Anniversary Gifts</div>
                <div className="search_suggest_item">Birthday Cards</div>
                <div className="search_suggest_item">Birthday Gifts</div>
                <div className="search_suggest_item">Christmas Cards</div>
                <div className="search_suggest_item">Christmas Gifts</div>
                <div className="search_suggest_item">New Year Cards</div>
                <div className="search_suggest_item">New Year Gifts</div>
                <div className="search_suggest_item">Pongal Cards</div>
                <div className="search_suggest_item">Pongal Gifts</div>
                <div className="search_suggest_item">Thank You Cards</div>
                <div className="search_suggest_item">Thank You Gifts</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchBox;
